.label-text {
  font-weight: 500;
}
.table th,
.datepicker table th,
.table td,
.datepicker table td {
  white-space: pre-wrap;
}

.icon {
  cursor: pointer;
}

.input-border {
  border-color: #003a78;
}

.input-settings-border {
  border-color: #fdb811;
}
