.card-icon {
  font-size: 25px;
  cursor: pointer;
}

.card-title {
  font-size: 35px;
}

.overlay {
  background-color: #0000005e;
}

.slider-image {
  font-weight: 500;
  font-size: 15px;
}

.img-card {
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
}

.slider-image {
  width: 50%;
  text-align: center;
  margin: auto;
}
.slider-table {
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .img-card {
    width: 100%;
    height: 100%;
  }

  .image {
    width: 100%;
  }
  .slider-image {
    width: 40%;
    text-align: left;
  }
}
