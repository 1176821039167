.login-btn {
  width: 100%;
}

.login-btn button {
  width: 100%;
}

.register-link a:hover {
  color: #fdb811;
}
