.all-icon {
  cursor: pointer;
}

.logo-card {
  width: 60%;
  background: transparent;
  border: none;
  box-shadow: none;
}

.logo-card-title {
  text-align: justify;
}

.card-about-title {
  font-weight: 500;
  margin-bottom: 1rem;
}

.pages-ul-list {
  list-style: none;
  padding: 0;
}

.accordion-item .accordion-header {
  border-top: 1px solid #e9ecef;
}

.icon-picker-textfield > div {
  width: 100%;
  border: 1px solid #e9ecef !important;
}
.icon-picker-textfield > div:after {
  flex-shrink: 0;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.icon-picker-textfield > div > div {
  font-size: 16px !important;
}
