.sidebar .sidebar-body .nav.mega-menu > .nav-item:hover > .nav-link {
  color: #fdb811;
  margin-left: 3px;
}

.sidebar .sidebar-body .nav.mega-menu {
  padding: 0 0 15px 33px;
}

.sidebar-dark .sidebar .sidebar-header {
  background: #548a99;
  border-bottom: 1px solid rgba(233, 236, 239, 0.1);
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}

.sidebar-dark .sidebar .sidebar-body {
  background: #548a99;
  border-right: 1px solid rgba(233, 236, 239, 0.1);
}
