.dashboard-icon {
  font-size: 2rem;
  color: #fff;
  width: 60px;
  height: 60px;
  background-color: #fdb811;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.dashboard-card {
  border: 1px solid #fdb8113b;
}

.icon-border {
  border-radius: 3px;
  width: 30%;
  height: auto;
}

.dashboard-content {
  width: 70%;
}

.dropdown-item i,
.tt-menu .tt-suggestion i,
.dropdown-item svg,
.tt-menu .tt-suggestion svg {
  color: #000;
}

.image-card {
  width: 40%;
}

.card-edit-image {
  object-fit: scale-down;
}
