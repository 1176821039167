.product_name {
  font-weight: 600;
}

.heading {
  font-weight: bold;
}

.add_image {
  width: 180px;
  height: 180px;
  border: 1px dotted grey;
  position: relative;
}

.image_icon {
  font-size: 40px;
  text-align: center;
  position: absolute;
  /* top: 35%;
  left: 35%; */
  color: grey;
  cursor: pointer;
}

.viewIcon {
  color: black;
}

.page_404 {
  padding: 40px 0;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
  margin-top: 10rem;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}

.ref-title {
  font-size: 20px;
}

.balance {
  color: grey;
}

.primaryimg_card {
  width: 230px;
  height: 200px;
}

.specfication_list {
  list-style-type: none;
  font-weight: 500;
}

.card-dotted {
  border: 1px dotted grey;
}

/* .img-card {
  width: 180px;
  height: 100%;
} */

.img-card svg {
  background: gray;
  color: white;
  padding: 5px;
  border-radius: 50%;
}

.img_card {
  /* width: 180px; */
  height: 100%;
}
.card-dotted {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .img_card {
    width: 180px;
    height: 180px;
  }
}
